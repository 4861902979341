import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress'
import Setting from '@/setting'
//import mainRouters from "@/router/routes"
//import bwrRouters from '@/pages/bwr/router/routes'
//import meijiejueRouters from '@/pages/meijiajie/router/routes'
import store from '@/store'
import { getCookie } from '@/utils/util'
import Content from '@/components/Shared/Content.vue'
import routers from './routes'

const Home = {
  path: '/',
  name: 'Home',
  meta: { title: 'Home', name: '工作台', enName: 'Workbench', keepalive: true, icon: "HomeFilled" },
  component: () => import('@/views/Home.vue'),
} 

// 默认路由
const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: { name: '登录' },
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/',
    name: 'Layout',
    component: () => import('@/components/Shared/Layout.vue'),
    children: [
      Home,
    ],
  },
  // {
  //   path: "/:catchAll(.*)",
  //   name: '404',
  //   component: () => import('@/views/PageError.vue'),
  // }
]

// 系统信息
const systemName = store.getters.systemName
const systemObj = {
    routes: routers,
    prefix: systemName == 'BWR' ? 'bwr' : systemName == 'Factory' ? 'factory': '',
}


const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL + systemObj.prefix), //history模式
  history: createWebHashHistory(), //hash模式
  routes,
})

let isAddRoute = false

// 前置守卫
router.beforeEach(async (to, from, next) => {
    /*console.log(to, from)*/
    const token = getCookie('access_token') 
    store.commit('HIDE_LOADING')

    // 显示页面加载进度
    if (Setting.showProgressBar) {
        NProgress.settings.showSpinner = false
        NProgress.start()
    }

    if (to.path === '/login') {
        isAddRoute = false
        next()
    } else if (!token) {
        isAddRoute = false
        next('/login')
    } else {
        if (isAddRoute) {
            next()
        } else {
          //console.log('systemName')
          //console.log(systemName)
          let tree_res = []
          
          //tree_res = systemObj.routes
          await tree_res.filter(item => {
            return router.addRoute('Layout', item);
          })
          
          // 获取菜单
          let resRigth = await store.dispatch('getMenu') || []
          
          // 添加路由
          for(let item of resRigth){
            item.component = Content
            if (item.children && item.children.length > 0){
                for(let items of item.children){
                  items.component = () => import('@/views/' + items.addr)
                }
            }

            if(item.meta.title == 'Home') continue

            router.addRoute(
              'Layout', 
              item
            )
          }
          tree_res.push(...resRigth)

          //添加其他的页面
          let FactoryWarning = {
              path: '/factoryorder/factory-order-warning',
              name: 'FactoryWarning',
              meta: { title: '单据报警', name: '工厂单据报警', enName: 'FactoryWarning', keepalive: false },
              component: () => import('@/views/FactoryOrder/FactoryOrderWarning.vue'),
          }
          router.addRoute(
            'Layout', 
            FactoryWarning
          )
          //await res.filter(item => {
          //    return router.addRoute('Layout', item);
          //})
          /*
          // 获取菜单 添加路由
          let code = store.getters.userInfo.UserCode

          console.log('get userinfo')
          console.log(store.getters.userInfo)
          if (code == 'ADMIN'){
            res.push(Home)
            res.push(...routers)
            for(let item of res){
                item && router.addRoute('Layout', item)
            }
          }else{
            console.log('get menu1')
            console.log(res)
            res = await store.dispatch('getMenu') || []
            console.log('get menu2')
            console.log(res)

            for(let item of res){
              console.log('menu list')
              console.log(item)
              item.component = Content
              if (item.children && item.children.length > 0){
                  for(let items of item.children){
                      let url = items.component.split('/')
                      items.component = () => import('@/views/' + url[2] + '/' + url[3])
                  }
              }

              if(item.meta.title == 'Home') continue
              router.addRoute('Layout', item)
            }
          }
          */
          //console.log('routers')
          //console.log(tree_res)

          // 添加 404 页面
          if (!router.hasRoute('404')) {
              router.addRoute({
                  path: '/:catchAll(.*)',
                  name: '404',
                  component: import('@/views/PageError.vue'),
              })
          }
          /*
          console.log(router.getRoutes());
          */
          // 添加到vuex
          store.commit('SAVE_ROUTES', [Home, ...tree_res])
          isAddRoute = true

          /*console.log(to)*/

          next({ ...to })
        }
      }
})

// 后置守卫
router.afterEach(route => {
  NProgress.done();
});

export default router
