<template>
    <router-view></router-view>
</template>
<script lang="ts">

</script>
<style lang="scss">
    @import './static/css/admin.scss';
    @import './static/css/theme.scss';

    #app {
        font-family: 'Microsoft YaHei','微软雅黑',Helvetica,Arial,sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        background: #f8f8f8;
    }

    nav {
        padding: 30px;

        a {
            font-weight: bold;
            color: #409eff;

            &.router-link-exact-active {
                color: #42b983;
            }
        }
    }

    .height-full {
        height: 100%;
    }

    .page-title {
        font-size: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;

        .title-txt {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    .page-title .title-icon {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .page-title .search-comm {
        margin-left: 20px;
        margin-right: 20px;
    }

    .page-operate {
        display: flex;
        justify-content: flex-start;
    }

    .el-alert--info {
        border-left: 5px solid #409eff;
        background: #409eff1a !important;
        color: #303133 !important;
    }

    .el-alert--info .el-alert__description {
        margin: 0 !important;
    }

    .el-table .el-link {
        line-height: 1.2;
        color: #409eff;
    }

    .dialog-tip .el-link {
        color: #409eff;
    }

    .dialog-tip .not-click {
        cursor: text;
    }

    .dialog-tip .el-link.not-click::after {
        content: unset !important;
    }

    .el-dialog__header {
        border-bottom: 1px solid #e4e7ed;
        margin-right: 0 !important;
    }

    .dialog-tip .el-alert--info {
        border-left: 0px !important;
    }

    .dialog-tip .el-alert__content {
        width: 100%;
        padding: 0;
    }

    .page-tip,
    .dialog-info {
        font-size: 12px;
        color: #999;
        line-height: 1.6;
    }

    .page-tip h4,
    .dialog-info h4 {
        font-size: 14px;
        font-weight: normal;
    }

    .page-tip .bold,
    .dialog-info .bold {
        color: #e6a23c;
        font-weight: bold;
    }

    .tip-content {
        width: 100%;
        height: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .form-item {
        width: 100%;
    }

    .dialog-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dialog-table h4 {
        font-size: 16px;
        color: #333;
    }

    .dialog-height {
        height: 65vh !important;
    }

    .form-item .el-form-item {
        width: 45%;
    }

    .form-item .el-form-item {
        margin-right: 0 !important;
    }

    .form-item textarea {
        width: 100%;
        height: 60px;
        font-size: 14px;
        box-shadow: 0 0 0 1px #dcdfe6 inset;
        border-radius: 4px;
        border: 0;
        padding: 11px;
    }

    .form-item textarea:focus-visible {
        outline: unset !important;
        box-shadow: 0 0 0 1px #409eff inset;
    }

    // 弹窗初始化居中
    .el-overlay-dialog {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .el-dialog {
        margin: 0 !important;
    }

    // li list-style
    .li-dot::before {
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background: #333;
        margin-right: 10px;
        content: '';
        position: relative;
        top: -2px;
    }

    // input number init
    input[type='number'] {
        -moz-appearance: textfield;
        appearance: textfield;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    // init card dialog and form-item
    .el-card {
        --el-card-padding: 12px !important;
    }

    .el-dialog {
        --el-dialog-padding-primary: 12px !important;
    }

    .el-dialog__title {
        font-size: 16px !important;
    }

    .el-dialog__body {
        padding: var(--el-dialog-padding-primary) !important;
    }

    .el-dialog__headerbtn {
        top: 0 !important;
    }

    .el-form-item {
        margin-bottom: 5px !important;
    }

    .el-alert {
        padding: 4px 12px !important;
    }

    .el-alert .el-form-item {
        margin-bottom: 0px !important;
    }

    .el-form-item__error {
        position: unset !important;
    }

    .v-contextmenu {
        z-index: 999999;
    }

    textarea {
        color: var(--el-input-text-color, var(--el-text-color-regular));
        font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif !important;
    }

    textarea:disabled {
        background-color: var(--el-disabled-bg-color);
        box-shadow: 0 0 0 1px var(--el-disabled-border-color) inset;
        color: var(--el-disabled-text-color);
    }

    .is-error textarea {
        box-shadow: 0 0 0 1px var(--el-color-danger) inset;
    }


    .el-scrollbar__bar {
        display: block !important;
        opacity: 1 !important;
        border-radius: 10px !important;
    }

    .el-scrollbar__thumb {
        background-color: #909399 !important;
        // opacity: 1 !important;
    }
    //
    .scroll-height {
        overflow-y: auto !important;
    }
    .scroll-height::-webkit-scrollbar {
        background-color: #fff;
        width: 6px !important;
        height: 6px !important;
    }

    .scroll-height::-webkit-scrollbar-track {
        background-color: #fff;
    }

    .scroll-height::-webkit-scrollbar-thumb {
        background-color: #909399;
    }
    .el-message.el-message--warning {
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 18px;
    }
    .el-message.el-message--warning > .el-message__content {
        font-size: 18px;
    }

    .el-table td.el-table__cell {
        padding: 4px 0 !important;
    }
    .el-table th.el-table__cell {
        padding: 6px 0 !important;
    }
    .el-table th .cell {
        white-space: pre-line;
    }
    .el-message-box {
        width: 440px !important;
    }
    .el-card__header,
    .el-card__body {
        padding: 7px 12px !important;
    }
    .el-overlay {
        background-color: rgba(0, 0, 0, 0.3) !important;
    }


    .el-form-item__label {
        line-height: 17px !important;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        white-space: pre-line;
    }
    .el-popper {
        max-width: 600px;
    }

    .tox-tinymce-aux {
        z-index: 9999 !important;
    }

    .el-notification .el-notification__content p{
        word-wrap: break-word;
        word-break: break-all;
    }
</style>
